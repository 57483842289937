
















import { Component, Prop, Vue } from "vue-property-decorator";
import { toCurrency } from "@/helpers/Formatter";

@Component
export default class BaseListTwoColumn extends Vue {
    @Prop() info!: any;
    @Prop({ default: "my-3" }) customClass!: string;

    formattedValue(info: any) {
        let formattedValue = info.value;

        if (info.type === "date")
            formattedValue = info.value.toLocaleString().substr(0, 10);
        if (info.type === "currency") formattedValue = toCurrency(info.value);

        return formattedValue;
    }
}
